<template>
  <div class="highlight-container highlight-height px-4 py-6">
    <div class="highlight-wrapper">
      <div class="third-row-container">
        <div>{{ $t(`label.videoCaption`) }}</div>
      </div>
      <div v-if="$vuetify.breakpoint.xsOnly">
        <v-row>
          <v-col cols="12">
            <iframe width="100%" height="300px" :src="embedUrl" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
          </v-col>
<!--          <v-col cols="5">-->
<!--            <img src="/static/image/banner/video-side.png" width="100%" height="100%" >-->
<!--          </v-col>-->
        </v-row>

      </div>
      <div v-else>
        <v-row>
          <v-col cols="7">
            <iframe width="100%" height="100%" :src="embedUrl" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
          </v-col>
          <v-col cols="5">
            <img src="/static/image/banner/video-side.png" width="100%" height="100%" >
          </v-col>
        </v-row>

      </div>
    </div>


  </div>
</template>

<script>
import moment from 'moment'
import { uiHelper } from '@/util'
import { SHARED } from '@/constants/constants'
import { ROUTE_NAME } from '@/constants/route.constants'
import orderBy from 'lodash/orderBy'
import take from 'lodash/take'

export default {
  name: 'appVideo',
  data: () => ({
    routeName: ROUTE_NAME
  }),
  created() {
    this.getVideoIdFromUrl()
  },
  computed: {
    embedUrl() {
      // Extract video ID from YouTube URL
      const videoId = this.getVideoIdFromUrl('https://www.youtube.com/embed/Kyz_vpbNHHE');

      // Generate embed URL
      return `https://www.youtube.com/embed/${videoId}`;
    },
  },
  methods: {
    getVideoIdFromUrl(url) {
      if (!url) {
        return ''; // Handle empty URL
      }

      const regExp =
          /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      if (match && match[2] && match[2].length === 11) {
        return match[2];
      } else {
        // Handle invalid YouTube URL
        return '';
      }
    },
    changeLanguage(language) {
      this.$store.dispatch(`${SHARED.LOADING}`)
      uiHelper.removeCookie('language')
      uiHelper.setCookie('language', language)
      window.location.reload()
    },
  }
}
</script>

<style lang="scss">
.highlight-container {
  background-color: var(--v-text_reverse-base);

  .highlight-height {
    height: 420px;
  }

  .highlight-wrapper {
    max-width: 1240px;
    margin: auto;
  }
}


@media (max-width: 599px) {
  .highlight-container {
    .highlight-height {
      height: 290px;
    }

    .highlight-wrapper {
      .matches-box {
        .match-item {
          min-width: 240px;
          min-height: 188px;
          height: 188px;
          gap:2px;
          padding: 8px 0 8px 0;
          justify-content: space-between;

          .league-name {
            font-size: 12px;
            font-weight: bold;
          }

          .match-date {
            font-size: 14px;
          }

          .match-team {
            font-size: 10px;
            font-weight: bold;

            .team-name{
              height: 35px;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .home-team{

            }

            .away-team{

            }

            .country-logo {
              width: 45px;
              height: 45px;
            }
            .odds{
              font-size: 10px;
              font-weight: normal;
            }
            .vs-team {
              font-size: 26px;
            }
            .versus{
              width: 47px;
              height: 30px;
            }
          }

          .match-datetime-div{
            .match-date{
              font-size: 10px;
              font-weight: normal;
            }
            .match-time{
              font-size: 18px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>
